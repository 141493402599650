import React, { Component } from 'react';

class Overview extends Component {
  render() {
    return (
      <div className="Overview">
        Hier sehen Sie bald den neuen Online-Shop von partisan-vodka.eu. Bitte haben Sie noch etwas Geduld.
      </div>
    );
  }
}

export default Overview;
